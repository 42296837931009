import { useSelector } from 'react-redux';

import { t } from '@lingui/macro';

import {
  useChannelLabelFormatter,
  useConceptLabelFormatter,
  useTagLabelFormatter,
  useUserOrTagLabelFormatter,
} from 'reducers/entityLabelFormatter';
import {
  campaignSelector,
  formElementLabelFormatterSelectorFactory,
} from 'selectors/campaign';
import {
  tagColorFormatterSelector,
  tagValueFormatterSelector,
} from 'selectors/entities';

import { createEntityField } from 'components/customer/campaign/useCampaignFields';
import { CSATCell } from 'components/ui/table/cells/dotCells';

import { getCSATScoreColor } from 'utils/colors';
import {
  incrementFormatterFactory,
  numberOnHundredFormatter,
  zeroPrecisionPercentFormatter,
} from 'utils/formatter';
import { useMemoizedFactorySelector } from 'utils/hooks';

import * as svars from 'assets/style/variables';

import Distribution from './chart/Distribution';
import HorizontalBar from './chart/HorizontalBar';
import Kpi, { kpiAccessorFactory } from './chart/Kpi';
import {
  channelDistributionChartConfigFactory,
  makeVolumeColumn,
  tagFormsChartConfigFactory,
  timeSeriesChartConfigFactory,
  volumeChartConfig,
} from './chart/commonChartConfiguration';

const getFeedbackCampaignMonitoringDashboardConfig = (campaignId) => {
  const tagColorFormatter = useSelector(tagColorFormatterSelector);
  const tagValueFormatter = useSelector(tagValueFormatterSelector);
  const channelLabelFormatter = useChannelLabelFormatter(campaignId)();
  const userOrTagLabelFormatter = useUserOrTagLabelFormatter();
  const formElementLabelFormatter = useSelector(
    formElementLabelFormatterSelectorFactory(campaignId)
  );
  const campaignConfiguration = useMemoizedFactorySelector(
    campaignSelector,
    campaignId
  );
  const tagLabelFormatter = useTagLabelFormatter();
  const conceptLabelFormatter = useConceptLabelFormatter();
  return [
    {
      row: 0,
      height: 3,
      children: [
        {
          row: 0,
          col: 0,
          width: 3,
          children: [
            {
              row: 0,
              col: '0-0',
              height: 1.5,
              ...volumeChartConfig,
            },
            {
              row: 0,
              col: '0-1',
              height: 1.5,
              hideMenu: true,
              component: Kpi,
              title: t`CSAT-score`,
              titleHelperHeader: t`CSAT-score-long`,
              titleHelper: t`csat-score-helper`,
              accessor: kpiAccessorFactory('csat'),
              meta: {
                color: getCSATScoreColor,
                formatter: numberOnHundredFormatter,
                increaseFormatter: incrementFormatterFactory(
                  zeroPrecisionPercentFormatter
                ),
              },
            },
          ],
        },
        {
          width: 7,
          row: 0,
          col: 1,
          ...timeSeriesChartConfigFactory({
            id: 'csat',
            label: t`CSAT-score`,
            accessor: 'csat',
            Cell: CSATCell,
            centered: true,
          }),
        },
        {
          row: 0,
          col: 2,
          width: 5,
          ...channelDistributionChartConfigFactory(channelLabelFormatter),
        },
      ],
    },
    {
      row: 1,
      height: 3,
      children: [
        {
          row: 1,
          col: 0,
          width: 6,
          title: t`satisfaction-level`,
          accessor: (data) => data.satisfaction_tag_distribution,
          component: Distribution,
          meta: {
            cellKey: 'satisfaction_tag',
            cellLabel: t`satisfaction-level`,
            indicatorKey: 'volume',
            indicatorLabel: t`volume`,
          },
          formatters: {
            labelFormatter: tagLabelFormatter,
            colorFormatter: tagColorFormatter,
          },
          makeTableData: ({ data, meta }) => ({
            data,
            defaultSorted: [{ id: meta.cellKey, desc: true }],
            columns: [
              createEntityField(
                (item) => item[meta.cellKey],
                {
                  id: meta.cellKey,
                  label: meta.cellLabel,
                  tag_set: campaignConfiguration.satisfaction_tag_set,
                },
                true,
                tagColorFormatter,
                tagLabelFormatter,
                tagValueFormatter,
                null,
                false
              ),
              makeVolumeColumn({ data, meta }),
            ],
          }),
        },
        {
          row: 1,
          col: 1,
          width: 6,
          title: t`distribution-over-categories-top-15`,
          component: HorizontalBar,
          accessor: (data) => data.distributions?.concept,
          meta: {
            cellKey: 'concept',
            cellLabel: t`concept`,
            entityKey: 'concept',
            indicatorKey: 'volume',
            indicatorLabel: t`volume`,
          },
          makeTableData: ({ data, meta }) => ({
            data,
            defaultSorted: [{ id: meta.indicatorKey, desc: true }],
            columns: [
              createEntityField(
                (item) => item[meta.cellKey],
                {
                  id: meta.cellKey,
                  label: meta.cellLabel,
                  accessor: meta.cellKey,
                  width: 1.5 * svars.defaultColumnMinWidth,
                  maxWidth: 1.5 * svars.defaultColumnMaxWidth,
                  centered: false,
                },
                true,
                tagColorFormatter,
                conceptLabelFormatter,
                null,
                null,
                false
              ),
              makeVolumeColumn({ data, meta }),
            ],
          }),
          formatters: {
            labelFormatter: conceptLabelFormatter,
            colorFormatter: tagColorFormatter,
          },
        },

        {
          row: 1,
          col: 2,
          width: 6,
          ...tagFormsChartConfigFactory(
            userOrTagLabelFormatter,
            formElementLabelFormatter,
            tagColorFormatter
          ),
        },
      ],
    },
  ];
};

export default getFeedbackCampaignMonitoringDashboardConfig;
