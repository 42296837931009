import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import { campaignChannelsItemsSelector } from 'reducers/entityLabelFormatter';

import CheckboxListFilter from 'components/ui/search/CheckboxListFilter';

function ChannelFilter({
  channels: values,
  onFilterChange,
  onFilterReset,
  loading,
}) {
  const { campaignId } = useParams();
  const channels = useSelector(campaignChannelsItemsSelector(campaignId));
  return (
    <CheckboxListFilter
      valueKey="channels"
      items={channels}
      values={values}
      onFilterChange={onFilterChange}
      onFilterReset={onFilterReset}
      loading={loading}
    />
  );
}

ChannelFilter.propTypes = {
  channels: PropTypes.arrayOf(PropTypes.string).isRequired,
  onFilterChange: PropTypes.func.isRequired,
  onFilterReset: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
ChannelFilter.defaultProps = { loading: false };

export default ChannelFilter;