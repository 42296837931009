import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { t } from '@lingui/macro';
import styled from 'styled-components';

import {
  feedbackSelector,
  fetchExportFeedback,
  fetchFeedback,
  focusFeedbackIdSelector,
  loadingSelector,
  paginationSelector,
  setFocusFeedbackId,
} from 'store/monitor/monitorSearchSlice';
import useGetCampaignExportTitle from 'store/monitor/useGetCampaignExportTitle';

import SearchExportButtonWithModal from 'components/ui/modal/SearchExportModal';
import { MonitoringSearchPagination } from 'components/ui/search/SearchPagination';
import ReactTable from 'components/ui/table/ReactTableUi';

import { capitalize } from 'utils/helpers';

import * as svars from 'assets/style/variables';

import useCampaignFields from '../useCampaignFields';
import CampaignReviewPane from './CampaignReviewPane';

const CAMPAIGN_SEARCH_PAGE_SIZE = 10;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: ${svars.spaceMedium};
  min-height: 80%;
  flex-grow: 1;
`;

const EXPORT_EXCLUDE_COLUMN_IDS = [];

function MonitoringSearchTab() {
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const feedbacks = useSelector(feedbackSelector);
  const loading = useSelector(loadingSelector);
  const focusFeedbackId = useSelector(focusFeedbackIdSelector);
  const pagination = useSelector(paginationSelector);
  const columns = useCampaignFields(true, true, false);
  const getExportTitle = useGetCampaignExportTitle(campaignId);

  const onSetFocusFeedbackId = useCallback(
    ({ id }) => dispatch(setFocusFeedbackId(id)),
    []
  );
  const onLoadMoreFeedback = useCallback(() => {
    dispatch(fetchFeedback({ campaignId, nextPage: true }));
  }, []);
  return (
    <Container>
      <ReactTable
        style={{ overflow: 'hidden' }}
        noDataText={null}
        loadingText={`${capitalize(t`loading`)} ...`}
        loading={loading}
        selectedRow={{ id: focusFeedbackId }}
        sortable={false}
        pageSize={20}
        rowKey="id"
        data={feedbacks}
        columns={columns}
        onRowClick={onSetFocusFeedbackId}
        scrollOnSelect
      />
      <MonitoringSearchPagination
        page={pagination.page}
        nPages={pagination.n_page}
        nResults={pagination.total}
        pageSize={CAMPAIGN_SEARCH_PAGE_SIZE}
        loading={loading}
        moreResultsMessage={capitalize(t`load-more-feedbacks`)}
        onPageChange={onLoadMoreFeedback}
      >
        <SearchExportButtonWithModal
          disabled={loading || !feedbacks?.length}
          columns={columns}
          excludeIds={EXPORT_EXCLUDE_COLUMN_IDS}
          defaultFilename={getExportTitle(t`responses`)()}
          nMaxChunks={pagination.total}
          getRawDataAction={fetchExportFeedback(campaignId)}
        />
      </MonitoringSearchPagination>
      <CampaignReviewPane visible={focusFeedbackId} />
    </Container>
  );
}

export default MonitoringSearchTab;
