import { useSelector } from 'react-redux';

import { Trans, t } from '@lingui/macro';

import {
  useChannelLabelFormatter,
  useUserOrTagLabelFormatter,
} from 'reducers/entityLabelFormatter';
import { formElementLabelFormatterSelectorFactory } from 'selectors/campaign';
import { tagColorFormatterSelector } from 'selectors/entities';
import {
  questionAnswerLabelFormatterSelectorFactory,
  questionLabelFormatterSelectorFactory,
} from 'selectors/survey';
import { campaignMonitoringHasScoreSelector } from 'store/monitor/monitorSlice';

import { ReactTableTextCell } from 'components/ui/table/cells/ReactTableCell';

import {
  incrementFormatterFactory,
  onePrecisionFloatFormatter,
} from 'utils/formatter';

import * as svars from 'assets/style/variables';

import { useSurveyQuestionsAlternativeDistributionOptions } from '../useCampaignFields';
import Kpi, { kpiAccessorFactory } from './chart/Kpi';
import {
  channelDistributionChartConfigFactory,
  distributionByChartConfigFactory,
  tagFormsChartConfigFactory,
  timeSeriesChartConfigFactory,
  volumeChartConfig,
} from './chart/commonChartConfiguration';

export const surveyQuestionsChartConfigFactory = (
  questionAnswerLabelFormatter,
  questionLabelFormatter
) =>
  distributionByChartConfigFactory(
    (data) => data.questions,
    (item) => item?.value,
    'question-distribution-select',
    t`question-distribution.no-data-message`,
    questionAnswerLabelFormatter,
    null,
    useSurveyQuestionsAlternativeDistributionOptions,
    questionLabelFormatter,
    svars.competitionEntityColors[3],
    svars.competitionEntityColors[1],
    t`answer`,
    250,
    ''
  );

const getSurveyCampaignMonitoringDashboardConfig = (campaignId) => {
  const tagColorFormatter = useSelector(tagColorFormatterSelector);
  const channelLabelFormatter = useChannelLabelFormatter(campaignId)();
  const userOrTagLabelFormatter = useUserOrTagLabelFormatter();
  const formElementLabelFormatter = useSelector(
    formElementLabelFormatterSelectorFactory(campaignId)
  );
  const questionLabelFormatter = useSelector(
    questionLabelFormatterSelectorFactory(campaignId)
  );
  const questionAnswerLabelFormatter = useSelector(
    questionAnswerLabelFormatterSelectorFactory(campaignId)
  );
  const hasScore = useSelector(campaignMonitoringHasScoreSelector);
  return [
    {
      row: 0,
      height: 3,
      children: [
        {
          row: 0,
          col: 0,
          width: 3,
          children: [
            {
              row: 0,
              col: '0-0',
              height: 1.5,
              ...volumeChartConfig,
            },
            {
              row: 0,
              col: '0-1',
              height: 1.5,
              hideMenu: true,
              component: Kpi,
              title: t`score`,
              titleHelperHeader: t`score-helper-header`,
              titleHelper: t`score-helper`,
              accessor: kpiAccessorFactory('score'),
              meta: {
                color: svars.scoreColor,
                formatter: onePrecisionFloatFormatter,
                increaseFormatter: incrementFormatterFactory(
                  onePrecisionFloatFormatter
                ),
                emptyDataMessage: hasScore ? (
                  <Trans id="campaign-monitoring.no-scored-answer" />
                ) : (
                  <p>
                    <Trans>
                      No score configured
                      <br />
                      Ask for support to configure help
                    </Trans>
                    {/* Navigate to{' '}
                      <RouterLink to="../../customize">
                        <Link base="true">
                          l&apos;administration de la campagne
                        </Link>
                      </RouterLink>{' '}
                      configure the score */}
                  </p>
                ),
              },
            },
          ],
        },
        {
          width: 7,
          row: 0,
          col: 1,
          ...timeSeriesChartConfigFactory({
            id: 'score',
            label: t`score`,
            accessor: 'score',
            Cell: ReactTableTextCell,
            centered: true,
          }),
        },
        {
          row: 0,
          col: 2,
          width: 5,
          ...channelDistributionChartConfigFactory(channelLabelFormatter),
        },
      ],
    },
    {
      row: 1,
      height: 3,
      children: [
        {
          row: 1,
          col: 0,
          width: 8,
          ...surveyQuestionsChartConfigFactory(
            questionAnswerLabelFormatter,
            questionLabelFormatter
          ),
        },

        {
          row: 1,
          col: 1,
          width: 8,
          ...tagFormsChartConfigFactory(
            userOrTagLabelFormatter,
            formElementLabelFormatter,
            tagColorFormatter
          ),
        },
      ],
    },
  ];
};
export default getSurveyCampaignMonitoringDashboardConfig;
