import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom/dist/index';

import { t } from '@lingui/macro';

import { campaignAclSelectorFactory } from 'selectors/campaign';
import { isFeedbackCampaignSelector } from 'selectors/survey';
import {
  feedbackByIdSelector,
  focusFeedbackIdSelector,
  focusOnNextFeedback,
  setFocusFeedbackId,
} from 'store/monitor/monitorSearchSlice';

import { FORMS_ICON_MAP } from 'components/customer/campaign/campaignSubRoutes';
import PaneFields from 'components/ui/panels/PaneFields';
import SidePane from 'components/ui/panels/SidePane';

import * as svars from 'assets/style/variables';

import useCampaignFields, {
  useAnnotationForm,
  useFeedbackForm,
  useIdentificationForm,
  useSatisfactionTag,
  useSurveyForm,
  useUrlForm,
} from '../useCampaignFields';

export const CAMPAIGN_COMMON_FORMS_FIELDS = {
  [useUrlForm]: {
    header: t`campaign-channel`,
    headerIcon: FORMS_ICON_MAP.urlFormFields?.icon,
  },
  [useIdentificationForm]: {
    header: t`respondent-identification`,
    headerIcon: FORMS_ICON_MAP.identificationFormFields?.icon,
  },
  [useAnnotationForm]: {
    alwaysShow: true,
    header: t`annotation`,
    headerIcon: FORMS_ICON_MAP.annotationFormFields?.icon,
    disabled: true,
  },
};

const feedbackCampaignPaneFields = {
  [useFeedbackForm]: {},
  [useSatisfactionTag]: {},
  texts: {
    header: t`texts`,
    headerIcon: 'quote right',
  },
  ...CAMPAIGN_COMMON_FORMS_FIELDS,
};

const surveyCampaignPaneFields = {
  date: {},
  ...CAMPAIGN_COMMON_FORMS_FIELDS,
  [useSurveyForm]: {
    wrapped: true,
    header: t`survey`,
    headerIcon: 'file alternate outline',
  },
};

function CampaignReviewPane() {
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const focusFeedbackId = useSelector(focusFeedbackIdSelector);
  const feedback = useSelector(feedbackByIdSelector(focusFeedbackId));
  const acl = useSelector(campaignAclSelectorFactory(campaignId));
  const fieldsMap = useCampaignFields(false, false, acl?.w);
  const isFeedbackCampaign = useSelector(
    isFeedbackCampaignSelector(campaignId)
  );
  const paneFields = isFeedbackCampaign
    ? feedbackCampaignPaneFields
    : surveyCampaignPaneFields;

  const onToggle = useCallback(() => {
    if (focusFeedbackId) dispatch(setFocusFeedbackId(null));
  }, [focusFeedbackId]);
  const onFocusPreviousFeedback = useCallback(() => {
    if (focusFeedbackId) dispatch(focusOnNextFeedback(false));
  }, [focusFeedbackId]);
  const onFocusNextFeedback = useCallback(() => {
    if (focusFeedbackId) dispatch(focusOnNextFeedback(true));
  }, [focusFeedbackId]);
  return (
    <SidePane
      onToggle={onToggle}
      animation="push"
      direction="right"
      visible={focusFeedbackId !== null}
      width="very wide"
      dimmed={0}
      dimmerColor={svars.colorLightestGrey}
    >
      <SidePane.Header
        title={t`response-details`}
        onToggle={onToggle}
        onSelectPrevious={onFocusPreviousFeedback}
        onSelectNext={onFocusNextFeedback}
        onSelectPreviousHelp={t`previous-response`}
        onSelectNextHelp={t`next-response`}
        gaCategory="Campaign - feedback panel"
      />
      <SidePane.Body>
        {feedback && focusFeedbackId ? (
          <>
            {Object.entries(paneFields).map(([key, extraProps]) => (
              <PaneFields
                key={`${key}-${focusFeedbackId}`}
                fields={fieldsMap[key]}
                reviewItem={feedback}
                {...extraProps}
              />
            ))}
          </>
        ) : null}
      </SidePane.Body>
    </SidePane>
  );
}
CampaignReviewPane.propTypes = {};
export default CampaignReviewPane;
