import { t } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import HelpTooltip from 'components/ui/HelpTooltip';
import EmptyDataVisualization from 'components/ui/visualization/EmptyDataVisualization';

import { getIncreaseColor } from 'utils/colors';

import * as svars from 'assets/style/variables';

export const kpiAccessorFactory =
  (name) =>
  ({ kpi }) =>
    kpi?.[name];

const KpiContainer = styled.span`
  height: 100%;
  display: inline-flex;
  align-items: center;
  margin: auto;
`;

const KpiValueContainer = styled.span`
  margin: ${svars.spaceMedium};
  text-align: center;
  min-width: 95px;
  font-size: ${svars.largeIconFontSize};
  line-height: ${svars.lineHeightSizeBig};

  color: ${({ color }) => color || svars.volumeColor};
`;
const KpiIncreaseContainer = styled.span`
  display: flex;
  flex-direction: row-reverse;
  margin: 0 ${svars.spaceNormal};
  color: ${svars.colorSecondary};
  white-space: pre;
  overflow: hidden;
  text-overflow: ellipsis;
`;

function Kpi({
  chartId,
  data,
  meta: { color, formatter, increaseFormatter, emptyDataMessage },
  loading,
}) {
  if (loading) return null;
  const { value, increase } = data || {};
  if (value === null)
    return <EmptyDataVisualization message={emptyDataMessage} /> || '-';
  return (
    <KpiContainer id={chartId}>
      <KpiValueContainer
        color={typeof color === 'function' ? color(value) : color}
        withIncrement={increase ? '1' : null}
      >
        {formatter(value)}
      </KpiValueContainer>
      <KpiIncreaseContainer
        style={{
          color:
            increase != null
              ? getIncreaseColor(increase, false)
              : svars.colorGrey,
        }}
      >
        <>
          <div style={{ display: 'flex' }}>
            {increase ? (
              <Icon
                style={{
                  marginBottom: svars.spaceNormal,
                  transform:
                    (increase > 0 && 'rotate(-45deg)') ||
                    (increase < 0 && 'rotate(45deg)') ||
                    'none',
                }}
                name="arrow right"
              />
            ) : null}
            <HelpTooltip
              help={[t`kpi.over-this-period-help`]}
              headerText={t`kpi.indicator-average-evolution`}
              baseColor
            />
          </div>
          <span>{increase != null ? increaseFormatter(increase) : 'N/A'}</span>
        </>
      </KpiIncreaseContainer>
    </KpiContainer>
  );
}
Kpi.propTypes = {
  chartId: PropTypes.string.isRequired,
  data: PropTypes.shape({
    value: PropTypes.number,
    increase: PropTypes.number,
  }).isRequired,
  meta: PropTypes.shape({
    formatter: PropTypes.func,
    increaseFormatter: PropTypes.func,
    color: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
    emptyDataMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
};
Kpi.defaultProps = {};
Kpi.minHeight = 110;
Kpi.minWidth = 240;

export default Kpi;
