import { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useParams } from 'react-router-dom';

import { Trans, t } from '@lingui/macro';
import { Dimmer } from 'semantic-ui-react';

import { isFeedbackCampaignSelector } from 'selectors/survey';
import {
  resetPresetSelection,
  setEditPreset,
} from 'store/monitor/campaignPresetSlice';
import { fetchChannels } from 'store/monitor/channelsSlice';
import {
  clearAllFilters,
  displayFiltersSelector,
  emptyFiltersSelectors,
  resetFilters,
  setFocusFeedbackId,
  toggleDisplayFilters,
  toggleFiltersPaneAndMaybeFetchFeedback,
} from 'store/monitor/monitorSearchSlice';
import {
  campaignMonitoringHasNoFeedbackSelector,
  loadingSelector,
} from 'store/monitor/monitorSlice';

import EmptyDataPage from 'components/ui/EmptyDataPage';
import { RouterLinkV2 } from 'components/ui/Link';
import { SecondaryButtonWithDeleteLabel } from 'components/ui/button';
import { SecondaryAccentTabButton } from 'components/ui/button/TabButton';
import { PageLayout } from 'components/ui/layout/Page';
import NavigationTabs from 'components/ui/navigation/NavigationTabs';
import emptyDashboardUrl from 'components/ui/svg/undraw_options_2fvi.svg';

import * as svars from 'assets/style/variables';

import MonitorPeriodSelect from './MonitorPeriodSelect';
import MonitoringFilters from './MonitoringFilters';
import MonitoringPresetSidePane from './MonitoringPresetSidePane';
import PresetActionsButton from './PresetActionsButton';

const panes = [
  {
    content: t`dashboard`,
    to: './dashboard',
    icon: 'chart pie',
  },
  {
    content: t`responses`,
    to: './search',
    icon: 'list alternate',
  },
];

function EmptyMonitoringDashboardActions() {
  return (
    <div>
      <Trans>
        Try changing the date range or filters, or{' '}
        <RouterLinkV2 base="true" to="../channels">
          navigate to distribution links
        </RouterLinkV2>{' '}
        to see if feedback were collected.
      </Trans>
    </div>
  );
}

function MonitoringDashboardLayout() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { campaignId } = useParams();
  const displayFilters = useSelector(displayFiltersSelector);
  const searchIsLoading = useSelector(loadingSelector, shallowEqual);
  const filtersAreEmpty = useSelector(emptyFiltersSelectors);
  const campaignViewHasNoFeedback = useSelector(
    campaignMonitoringHasNoFeedbackSelector
  );
  const [displayPresets, setDisplayPresets] = useState(false);
  const onTogglePresets = () => {
    if (displayFilters) dispatch(toggleDisplayFilters(false));
    setDisplayPresets(!displayPresets);
  };
  const isFeedbackCampaign = useSelector(
    isFeedbackCampaignSelector(campaignId)
  );

  const onToggleFilters = useCallback(
    () => dispatch(toggleDisplayFilters(!displayFilters)),
    [displayFilters]
  );

  const onValidateFilters = useCallback(
    (lockPane, forceUpdate) => () => {
      dispatch(setFocusFeedbackId(null));
      dispatch(
        toggleFiltersPaneAndMaybeFetchFeedback({
          campaignId,
          lockPane,
          forceUpdate,
        })
      );
    },
    [displayFilters, onToggleFilters, campaignId]
  );

  const resetAllFilters = useCallback(
    (event) => {
      event.stopPropagation();
      dispatch(clearAllFilters());
      dispatch(resetPresetSelection());
      onValidateFilters(true, true)();
    },
    [resetFilters]
  );
  const onDisplayPresetEditor = useCallback(
    (preset) => {
      dispatch(setEditPreset(preset));
      if (!displayPresets) onTogglePresets();
    },
    [displayPresets, onTogglePresets]
  );

  useEffect(() => {
    // First queries to aggregates and feedbacks fetching
    dispatch(fetchChannels({ campaignId, page: null, itemsPerPage: null }));
    onValidateFilters(true, true)();
  }, []);
  useEffect(() => {
    dispatch(setFocusFeedbackId(null));
  }, [location]);
  const presetButton = (
    <PresetActionsButton
      onDisplayPresetEditor={onDisplayPresetEditor}
      onTogglePresets={onTogglePresets}
      displayPresets={displayPresets}
      disabled={searchIsLoading}
    />
  );
  return (
    <>
      <NavigationTabs panes={panes} disabled={campaignViewHasNoFeedback}>
        {presetButton}
        <MonitoringPresetSidePane
          displayPresets={displayPresets}
          onTogglePresets={onTogglePresets}
          onToggleFilters={onToggleFilters}
        />
        <MonitorPeriodSelect
          disabled={searchIsLoading}
          onValidateFilters={onValidateFilters}
        />
        <span style={{ margin: 'auto 0' }}>
          <SecondaryButtonWithDeleteLabel
            ButtonComponent={SecondaryAccentTabButton}
            style={{ marginLeft: svars.spaceNormalLarge }}
            onClick={onToggleFilters}
            onLabelClick={resetAllFilters}
            loading={searchIsLoading}
            showLabel={!filtersAreEmpty}
          />
        </span>
      </NavigationTabs>
      <MonitoringFilters
        isFeedbackCampaign={isFeedbackCampaign}
        visible={displayFilters}
        onValidateFilters={onValidateFilters()}
        resetAllFilters={resetAllFilters}
        onTogglePresets={onTogglePresets}
      >
        {presetButton}
      </MonitoringFilters>
      <PageLayout style={{ height: '100%' }}>
        <Dimmer.Dimmable
          dimmed={campaignViewHasNoFeedback}
          style={{ display: 'flex', height: '100%' }}
        >
          <Dimmer
            style={{ zIndex: 3 }}
            active={campaignViewHasNoFeedback}
            inverted
          >
            <EmptyDataPage
              maxHeight="300px"
              layout="row"
              i18nHeaderText={t`campaign-monitoring.no-data-message-header`}
              illustrationUrl={emptyDashboardUrl}
              actionComponent={<EmptyMonitoringDashboardActions />}
            />
          </Dimmer>
          <Outlet />
        </Dimmer.Dimmable>
      </PageLayout>
    </>
  );
}

MonitoringDashboardLayout.propTypes = {};
MonitoringDashboardLayout.defaultProps = {};

export default MonitoringDashboardLayout;
